import gql from 'graphql-tag';

export const ReadTimeQuery = gql`
	query ReadTimeQuery($contentId: ID) {
		content(id: $contentId) {
			nodes {
				id
				type
				body {
					dynamic {
						value
						representation
					}
				}
			}
		}
	}
`;
