import React, { useCallback, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N500 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { READ_TIME_METRIC } from './perf.config';

export const i18n = defineMessages({
	readTime: {
		id: 'read-time.by-line.estimated-read-time',
		defaultMessage: '{readTime} min read',
		description: 'Estimated time to read this page',
	},
	tooltipDescription: {
		id: 'read-time.tooltip-description',
		defaultMessage: 'Estimated read time',
		description: 'Tooltip message',
	},
});

const styledReadTime = css({
	marginRight: token('space.050', '4px'),
	paddingLeft: token('space.075', '6px'),
	color: token('color.text.subtle', N500),

	'&::before': {
		content: '•',
		display: 'inline-block',
		color: token('color.text.subtle', N500),
		paddingRight: token('space.100', '8px'),
		textDecoration: 'none',
	},
});

type ReadTimeComponentType = {
	pageId: string;
	readTime: number | null;
};

export function ReadTimeComponent({ pageId, readTime }: ReadTimeComponentType) {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireTooltipEvent = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'displayed',
				actionSubject: 'tooltip',
				actionSubjectId: 'estimatedReadTime',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	useEffect(() => {
		if (readTime) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'confluence-frontend',
					action: 'displayed',
					actionSubject: 'readTime',
					actionSubjectId: pageId,
					attributes: {
						readTime,
					},
				},
			}).fire();
		}
	}, [pageId, readTime, createAnalyticsEvent]);

	return (
		// styled elements cannot be the primary component as ssr-placeholder attributes
		// will erroneously be added to the <style> componens during SSR. To fix this,
		// this component is wrapped in a div with an inline style
		<div
			data-vc="read-time"
			{...ssrPlaceholderIdProp}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'inline-flex' }}
		>
			<span css={styledReadTime}>
				<Tooltip
					tag="span"
					content={<FormattedMessage {...i18n.tooltipDescription} />}
					onShow={fireTooltipEvent}
				>
					<FormattedMessage {...i18n.readTime} values={{ readTime }} />
				</Tooltip>
			</span>
			<PageSegmentLoadEnd metric={READ_TIME_METRIC} />
		</div>
	);
}
