import type { ADDoc } from '@atlaskit/editor-common/validator';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import { reduce } from '@atlaskit/adf-utils/traverse';

export type ContentFeatures = {
	rawLength: number;
	textLength: number;
	wordCount: number;
	imageCount: number;
	inlineMacroCount: number;
	macroCount: number;
	tableCount: number;
};

const wordCount = (text?: string) => {
	if (text) {
		const matches = text.match(/\S+/g);
		if (matches) {
			return matches.length;
		}
	}
	return 0;
};

export const extractContentFeatures = (adf: ADDoc): ContentFeatures => {
	const initialFeatures: ContentFeatures = {
		rawLength: JSON.stringify(adf).length,
		textLength: 0,
		wordCount: 0,
		imageCount: 0,
		inlineMacroCount: 0,
		macroCount: 0,
		tableCount: 0,
	};
	const result = reduce<ContentFeatures>(
		adf,
		(currentValue, node: ADFEntity) => {
			switch (node.type) {
				case 'text':
					return {
						...currentValue,
						textLength: currentValue.textLength + (node.text ? node.text.length : 0),
						wordCount: currentValue.wordCount + wordCount(node.text),
					};
				case 'media':
					return {
						...currentValue,
						imageCount: currentValue.imageCount + 1,
					};
				case 'inlineExtension':
					return {
						...currentValue,
						inlineMacroCount: currentValue.macroCount + 1,
					};
				case 'extension':
					return {
						...currentValue,
						macroCount: currentValue.macroCount + 1,
					};
				case 'table':
					return {
						...currentValue,
						tableCount: currentValue.tableCount + 1,
					};
			}
			return currentValue;
		},
		initialFeatures,
	);
	return result;
};
